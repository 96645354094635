<template>
  <div class="mb-2">
    <label class="font-weight-bolder font-medium-4 mb-1">{{ $t('System Behavior') }}</label>
    <div>
      <label
        :for="searchDefaults['is_searches_show_inactive_records']"
        class="font-weight-bolder ml-2"
      >Searches show inactive records</label>
      <component
        :is="searchDefaults[field].type"
        v-for="field in ['is_searches_show_inactive_records']"
        :key="field"
        :ref="`${field}_ref`"
        v-model="searchDefaultsForm[field]"
        v-bind="getProps(field)"
        :is-editable="!$can(ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS.action,ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS.subject) || isEdit"
      />
    </div>
  </div>
</template>
<script>
import { BCol } from 'bootstrap-vue'
import config from '@/views/settings/customer/defaults/defaultsConfig'

export default {
  name: 'SearchDefault',
  components: { BCol },
  props: {
    isEdit: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    searchDefaultsForm() {
      return this.$store.state[this.MODULE_NAME].SearchDefaultsForm
    },
  },
  methods: {
    getProps(fieldName) {
      return {
        is: this.searchDefaults[fieldName].type,
        field: this.searchDefaults[fieldName],
        name: fieldName,
      }
    },
  },
  setup() {
    const MODULE_NAME = 'settings-customer'
    const MODULE_NAME_CLONE = 'cloneData'
    const { searchDefaults, ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS } = config()
    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      searchDefaults,
      ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS,
    }
  },
}
</script>

<style>

</style>
