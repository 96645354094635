<template>
  <div>
    <label class="font-weight-bolder font-medium-4">{{ $t('Business Entity Defaults') }}</label>
    <b-row>
      <b-col md="6">
        <label
          :for="businessEntityDefaults['status']"
          class="font-weight-bolder"
        >Default Status</label>
        <component
          :is="businessEntityDefaults[field].type"
          v-for="field in ['status']"
          :key="field"
          :ref="`${field}_ref`"
          v-model="businessEntityDefaultsForm[field]"
          v-bind="getProps(field)"
          :is-b-form-group="businessEntityDefaults[field].isBFormGroup"
          :is-editable="!$can(ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS.action,ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS.subject) || isEdit"
        />
        <component
          :is="businessEntityDefaults[field].type"
          v-for="field in ['currency_id','payment_term_id', 'price_tier_id', 'tax_rule_id',]"
          :key="field"
          :ref="`${field}_ref`"
          v-model="businessEntityDefaultsForm[field]"
          v-bind="getProps(field)"
          :is-editable="!$can(ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS.action,ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS.subject) || isEdit"
        />
        <!--                <div>-->
        <!--                  <label class="font-weight-bolder">{{ $t('Default Payment Options') }}</label>-->
        <!--                  <div-->
        <!--                    v-for="({ name, id}) in paymentOptions"-->
        <!--                    :key="id + 'paymentOption'"-->
        <!--                    class="d-flex align-content-center justify-content-between"-->
        <!--                  >-->
        <!--                    <label-->
        <!--                      :for="id.toString()"-->
        <!--                      class="inputLabelNormal d-flex align-items-center"-->
        <!--                    >{{ name }}</label>-->
        <!--                    <b-form-checkbox-->
        <!--                      :id="id.toString()"-->
        <!--                      type="checkbox"-->
        <!--                      :checked="isChecked(id, businessEntityDefaultsForm.payment_option_ids)"-->
        <!--                      class="d-flex check&#45;&#45;disabled"-->
        <!--                      :disabled="!$can( ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS.action,ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS.subject) || isEdit"-->
        <!--                      @change="toggleIds(id, 'payment_option_ids')"-->
        <!--                    />-->
        <!--                  </div>-->
        <!--                </div>-->
        <div>
          <label class="font-weight-bolder mt-2">{{ $t('Default Customer Rules') }}</label>
          <div
            v-for="{ id, name } in customerRules"
            :key="id + 'customerRules'"
            class="d-flex align-content-center"
          >
            <b-form-checkbox
              :id="id.toString() + name"
              type="checkbox"
              :checked="isChecked(id, businessEntityDefaultsForm.customer_rule_ids)"
              class="d-flex align-items-center check--disabled"
              :disabled="!$can(ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS.action,ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS.subject ) || isEdit"
              @change="toggleIds(id, 'customer_rule_ids')"
            />
            <label
              :for="id.toString() + name"
              class="inputLabelNormal d-flex align-items-center"
            >{{ name }}</label>
          </div>
        </div>
      </b-col>
      <b-col md="6">
        <component
          :is="businessEntityDefaults[field].type"
          v-for="field in ['delivery_type_id', 'discount', 'credit_limit']"
          :key="field"
          :ref="`${field}_ref`"
          v-model="businessEntityDefaultsForm[field]"
          v-bind="getProps(field)"
          :is-editable="!$can( ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS.action,ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS.subject) || isEdit"
        />
        <div>
          <label class="inputLabel">{{ $t('Default Selected Business Transactions') }}</label>
          <component
            :is="businessEntityDefaults[field].type"
            v-for="field in ['allow_to_rent_from_subrent', 'allow_to_purchase_from_supplier']"
            :key="field"
            v-model="businessEntityDefaultsForm[field]"
            v-bind="getProps(field)"
            :is-editable="!$can(ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS.action,ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS.subject) || isEdit"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { required } from '@/libs/validations/validations'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import { ValidationObserver } from 'vee-validate'
import {
  BButton, BCol, BForm, BRow, BFormCheckbox,
} from 'bootstrap-vue'
import config from '../../defaultsConfig'

export default {
  name: 'BusinessEntityDefaults',
  components: {
    ErrorAlert,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      error: {},
      required,
    }
  },
  computed: {
    businessEntityDefaultsForm() {
      return this.$store.state[this.MODULE_NAME].BusinessEntityDefaultsForm
    },
    paymentOptions() {
      return this.$store.state.listModule.paymentOptionList
    },
    customerRules() {
      return this.$store.state.listModule.customerRulesList
    },
  },
  methods: {
    isChecked(id, listArray) {
      return Array.isArray(listArray) && listArray.includes(String(id))
    },
    toggleIds(id, field) {
      if (this.businessEntityDefaultsForm[field].includes(String(id))) {
        this.businessEntityDefaultsForm[field] = this.businessEntityDefaultsForm[field].filter(pId => pId !== String(id))
      } else {
        this.businessEntityDefaultsForm[field].push(String(id))
      }
    },
    getProps(fieldName) {
      return {
        is: this.businessEntityDefaults[fieldName].type,
        field: this.businessEntityDefaults[fieldName],
        name: fieldName,
      }
    },
  },
  setup() {
    const MODULE_NAME = 'settings-customer'
    const MODULE_NAME_CLONE = 'cloneData'
    const { businessEntityDefaults, ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS } = config()
    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      businessEntityDefaults,
      ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS,
    }
  },
}
</script>
<style lang="scss">
[type=checkbox]:checked+label:before {
  background-color: transparent !important;
}
.check--disabled {
  .custom-control-input:disabled + label::after {
    background-color: #efefef;
    border-radius: 5px;
    opacity: 0.5;
  }
}

</style>
