<template>
  <div>
    <b-row>
      <b-col>
        <div class="d-flex justify-content-end">
          <feather-icon
            v-if="organizationRequiresEditConfirmation"
            v-b-tooltip.noninteractive.hover.bottom
            :title="$t('Edit')"
            :icon="isEdit ? 'LEditIconUpdated' : 'LNoneEditIconUpdated'"
            size="36"
            class="cursor-pointer"
            @click="isEditAble()"
          />
        </div>
      </b-col>
    </b-row>
    <error-alert
      :fields="fields"
      :error="error"
    />
    <b-form>
      <validation-observer
        ref="customerSettingsCreateVal"
      >
        <search-default
          :is-edit="isEdit"
        />
        <business-entity-defaults
          class="mb-2"
          :is-edit="isEdit"
        />
        <contact-defaults :is-edit="isEdit" />
        <address-defaults :is-edit="isEdit" />
        <customer-verification-defaults :is-edit="isEdit" />
        <portal to="body-footer">
          <div class="d-flex mt-2 pb-1 justify-content-between">
            <div>
              <b-button
                v-if="isFormChanged"
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="reload"
              >
                <feather-icon
                  icon="LCancelIcon"
                  size="16"
                />
                {{ $t('Cancel') }}
              </b-button>
              <b-button
                v-else
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="$router.push({ name: 'settings' }); clear"
              >
                {{ $t('Back to Setting') }}
              </b-button>
            </div>
            <div>
              <b-button
                v-if="$can(ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS.action,ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS.subject)"
                variant="success"
                class="saveBtn font-medium-1 font-weight-bolder"
                :disabled="!isFormChanged"
                @click="submit"
              >
                {{ $t('SAVE') }}
              </b-button>
            </div>
          </div>
        </portal>
      </validation-observer>
    </b-form>
    <b-s-warning-modal ref="confirm-modal" />
  </div>
</template>
<script>
import { ValidationObserver } from 'vee-validate'
import {
  BButton, BCol, BForm, BRow, VBTooltip,
} from 'bootstrap-vue'
import { scrollToError } from '@core/utils/utils'
import { required } from '@/libs/validations/validations'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import store from '@/store'
import SearchDefault from '@/views/settings/customer/defaults/components/SystemBehavior/SearchDefault.vue'
import config from '../defaultsConfig'
import ContactDefaults from './ContactDefaults/ContactDefaults.vue'
import AddressDefaults from './AddressDefaults/AddressDefaults.vue'
import BusinessEntityDefaults from './BusinessEntityDefaults/BusinessEntityDefaults.vue'
import CustomerVerificationDefaults from './CustomerVerificationDefaults/CustomerVerificationDefaults.vue'

export default {
  name: 'DefaultForm',
  components: {
    SearchDefault,
    BCol,
    BRow,
    BForm,
    BButton,
    ErrorAlert,
    ContactDefaults,
    AddressDefaults,
    ValidationObserver,
    BusinessEntityDefaults,
    CustomerVerificationDefaults,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      error: {},
      required,
      onSubmit: false,
      isEdit: store.state.app.organizationRequiresEditConfirmation,
    }
  },
  computed: {
    organizationRequiresEditConfirmation() {
      return store.state.app.organizationRequiresEditConfirmation
    },
    businessEntityDefaultsForm() {
      return this.$store.state[this.MODULE_NAME].BusinessEntityDefaultsForm
    },
    contactDefaultsForm() {
      return this.$store.state[this.MODULE_NAME].ContactDefaultsForm
    },
    addressDefaultsForm() {
      return this.$store.state[this.MODULE_NAME].AddressDefaultsForm
    },
    customerVerificationDefaultsForm() {
      return this.$store.state[this.MODULE_NAME].CustomerVerificationDefaultsForm
    },
    searchDefaultsForm() {
      return this.$store.state[this.MODULE_NAME].SearchDefaultsForm
    },
    paymentOptions() {
      return this.$store.state.listModule.paymentOptionList
    },
    customerRules() {
      return this.$store.state.listModule.customerRulesList
    },
    settingCustomerDefaultsFormClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].settingCustomerDefaultsForm
    },
    isFormChanged() {
      return JSON.stringify({
        ...this.searchDefaultsForm,
        ...this.contactDefaultsForm,
        ...this.addressDefaultsForm,
        ...this.businessEntityDefaultsForm,
        ...this.customerVerificationDefaultsForm,
      }) !== this.settingCustomerDefaultsFormClone
    },
  },
  methods: {
    isEditAble() {
      this.isEdit = !this.isEdit
    },
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.onSubmit)
    },
    submit() {
      this.error = {}
      this.onSubmit = true

      this.$refs.customerSettingsCreateVal.validate()
        .then(success => {
          if (success) {
            const searchDefaultsFormData = this.searchDefaultsForm
            const businessEntityDefaultsFormData = this.businessEntityDefaultsForm
            const contactDefaultsFormData = this.contactDefaultsForm
            const addressDefaultsFormData = this.addressDefaultsForm
            const customerVerificationDefaultsFormData = this.customerVerificationDefaultsForm

            const searchDefaults = this.mappingFields(Object.keys(this.businessEntityDefaults), searchDefaultsFormData, {
              is_searches_show_inactive_records: `${searchDefaultsFormData.is_searches_show_inactive_records}`,
            })
            const businessEntityDefaults = this.mappingFields(Object.keys(this.searchDefaults), businessEntityDefaultsFormData, {
              status: `${businessEntityDefaultsFormData.status}`,
              currency_id: businessEntityDefaultsFormData.currency_id.id,
              tax_rule_id: businessEntityDefaultsFormData.tax_rule_id.id,
              warehouse_id: businessEntityDefaultsFormData.warehouse_id.id,
              price_tier_id: businessEntityDefaultsFormData.price_tier_id.id,
              payment_term_id: businessEntityDefaultsFormData.payment_term_id.id,
              customer_rule_ids: businessEntityDefaultsFormData.customer_rule_ids,
              delivery_type_id: businessEntityDefaultsFormData.delivery_type_id.id,
              discount: businessEntityDefaultsFormData.discount,
              credit_limit: businessEntityDefaultsFormData.credit_limit,
              payment_option_ids: businessEntityDefaultsFormData.payment_option_ids,
              allow_to_rent_from_subrent: `${businessEntityDefaultsFormData.allow_to_rent_from_subrent}`,
              allow_to_purchase_from_supplier: `${businessEntityDefaultsFormData.allow_to_purchase_from_supplier}`,
            })

            const contactDefaults = this.mappingFields(Object.keys(this.contactDefaults), contactDefaultsFormData, {
              contact_type_is_account_holder: `${contactDefaultsFormData.contact_type_is_account_holder}`,
              contact_type_is_billing: `${contactDefaultsFormData.contact_type_is_billing}`,
              contact_type_is_can_place_orders: `${contactDefaultsFormData.contact_type_is_can_place_orders}`,
              contact_type_is_can_place_shipping: `${contactDefaultsFormData.contact_type_is_can_place_shipping}`,
              contact_default_status: `${contactDefaultsFormData.contact_default_status}`,
              contact_preferred_contact_method: contactDefaultsFormData.contact_preferred_contact_method,
            })
            const addressDefaults = this.mappingFields(Object.keys(this.addressDefaults), addressDefaultsFormData, {
              address_type_is_account_holder: `${addressDefaultsFormData.address_type_is_account_holder}`,
              address_type_is_billing: `${addressDefaultsFormData.address_type_is_billing}`,
              address_type_is_shipping: `${addressDefaultsFormData.address_type_is_shipping}`,
              address_status: `${addressDefaultsFormData.address_status}`,
            })
            const customerVerificationDefaults = this.mappingFields(Object.keys(this.customerVerificationDefaults), customerVerificationDefaultsFormData, {
              verification_decuctibles_always_require: `${customerVerificationDefaultsFormData.verification_decuctibles_always_require}`,
              verification_default_status_id: `${customerVerificationDefaultsFormData.verification_default_status_id.id}`,
            })

            const totalDataToSend = {
              ...searchDefaults,
              ...contactDefaults,
              ...addressDefaults,
              ...businessEntityDefaults,
              ...customerVerificationDefaults,
            }

            this.sendNotification(this, totalDataToSend, `${this.MODULE_NAME}/updateDefaultSettings`)
              .then(() => {
                this.$emit('refetch')
                this.clear()
              })
              .catch(err => {
                this.error = err.response?.data
              }).finally(() => {
                this.onSubmit = false
              })
          } else {
            scrollToError(this, this.$refs.customerSettingsCreateVal)
          }
        })
    },
    reload() {
      this.$refs.customerSettingsCreateVal.reset()
      const smth = JSON.parse(this.settingCustomerDefaultsFormClone)
      const searchDefaults = this.mappingFields(Object.keys(this.searchDefaults), smth, {
        is_searches_show_inactive_records: smth.is_searches_show_inactive_records,
      })
      const businessEntityDefaults = this.mappingFields(Object.keys(this.businessEntityDefaults), smth, {
        currency_id: smth.currency_id,
        payment_term_id: smth.payment_term_id,
        price_tier_id: smth.price_tier_id,
        tax_rule_id: smth.tax_rule_id,
        payment_option_ids: smth.payment_option_ids,
        customer_rule_ids: smth.customer_rule_ids,
        delivery_type_id: smth.delivery_type_id,
      })

      const contactDefaults = this.mappingFields(Object.keys(this.contactDefaults), smth, {
        contact_preferred_contact_method: smth.contact_preferred_contact_method,
      })

      const addressDefaults = this.mappingFields(Object.keys(this.addressDefaults), smth)
      const customerVerificationDefaults = this.mappingFields(Object.keys(this.customerVerificationDefaults), smth, {
        verification_first_rental: +smth.verification_first_rental,
        verification_recurrent_customers: +smth.verification_recurrent_customers,
      })
      this.$store.commit(`${this.MODULE_NAME}/SET_SEARCH_DEFAULTS_FORM`, searchDefaults)
      this.$store.commit(`${this.MODULE_NAME}/SET_CONTACT_DEFAULTS_FORM`, contactDefaults)
      this.$store.commit(`${this.MODULE_NAME}/SET_ADDRESS_DEFAULTS_FORM`, addressDefaults)
      this.$store.commit(`${this.MODULE_NAME}/SET_BUSINESS_ENTITY_DEFAULTS_FORM`, businessEntityDefaults)
      this.$store.commit(`${this.MODULE_NAME}/SET_CUSTOMER_VERIFICATION_DEFAULTS_FORM`, customerVerificationDefaults)
    },
    clear() {
      this.$refs.customerSettingsCreateVal.reset()
      this.$store.commit(`${this.MODULE_NAME}/SET_SEARCH_DEFAULTS_FORM`, {})
      this.$store.commit(`${this.MODULE_NAME}/SET_CONTACT_DEFAULTS_FORM`, {})
      this.$store.commit(`${this.MODULE_NAME}/SET_ADDRESS_DEFAULTS_FORM`, {})
      this.$store.commit(`${this.MODULE_NAME}/SET_BUSINESS_ENTITY_DEFAULTS_FORM`, {})
      this.$store.commit(`${this.MODULE_NAME}/SET_CUSTOMER_VERIFICATION_DEFAULTS_FORM`, {})
    },
  },
  setup() {
    const MODULE_NAME = 'settings-customer'
    const MODULE_NAME_CLONE = 'cloneData'
    const {
      searchDefaults,
      businessEntityDefaults, addressDefaults, customerVerificationDefaults, contactDefaults, ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS,
    } = config()

    const fields = {
      ...searchDefaults,
      ...contactDefaults,
      ...addressDefaults,
      ...businessEntityDefaults,
      ...customerVerificationDefaults,
    }

    return {
      fields,
      MODULE_NAME,
      searchDefaults,
      contactDefaults,
      addressDefaults,
      MODULE_NAME_CLONE,
      businessEntityDefaults,
      customerVerificationDefaults,
      ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS,
    }
  },
}
</script>
