<template>
  <div>
    <skeletons v-if="loading" />
    <default-form
      v-else
      ref="default-form"
      @refetch="refetch"
    />
  </div>
</template>

<script>
import store from '@/store'
import customerModule from '@/store/settings/customer/customer-defaults'
import { onUnmounted } from '@vue/composition-api'
import jwtDefaultConfig from '@/auth/jwt/jwtDefaultConfig'
import DefaultForm from './components/DefaultsForm.vue'
import config from './defaultsConfig'
import Skeletons from './components/Skeletons.vue'

export default {
  name: 'Defaults',
  components: { Skeletons, DefaultForm },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    verificationDefaultStatusList() {
      return this.$store.state.listModule.verificationDefaultStatus
    },
  },
  created() {
    this.$emit('updateMenu', 'settings-customer-defaults')
    this.$store.dispatch('listModule/paymentOptionList')
    this.$store.dispatch('listModule/customerRulesList')
    this.refetch()
  },
  beforeRouteLeave(to, from, next) {
    if (!this.loading) this.$refs['default-form'].showConfirmationModal(next)
  },
  methods: {
    refetch() {
      this.loading = true
      this.$store.dispatch(`${this.MODULE_NAME}/getDefaultSettings`).then(res => {
        const { data } = res.data

        const searchDefaults = this.mappingFields(Object.keys(this.searchDefaults), data, {
          is_searches_show_inactive_records: data?.is_searches_show_inactive_records,
        })
        const businessEntityDefaults = this.mappingFields(Object.keys(this.businessEntityDefaults), data, {
          currency_id: data.currency,
          payment_term_id: data.payment_term,
          price_tier_id: data.price_tier,
          tax_rule_id: data.tax_rule,
          payment_option_ids: data.payment_option_ids,
          customer_rule_ids: data.customer_rule_ids,
          delivery_type_id: data.delivery_type,
          warehouse_id: data.warehouse,
        })

        const contactDefaults = this.mappingFields(Object.keys(this.contactDefaults), data, {
          contact_preferred_contact_method: data.contact_preferred_contact_method,
        })

        const addressDefaults = this.mappingFields(Object.keys(this.addressDefaults), data)
        const customerVerificationDefaults = this.mappingFields(Object.keys(this.customerVerificationDefaults), data, {
          verification_first_rental: +data.verification_first_rental,
          verification_recurrent_customers: +data.verification_recurrent_customers,
          verification_default_status_id: this.verificationDefaultStatusList.find(verification => verification.id === data.verification_default_status_id),
        })

        const totalDataForCloneData = {
          ...searchDefaults,
          ...contactDefaults,
          ...addressDefaults,
          ...businessEntityDefaults,
          ...customerVerificationDefaults,
        }
        localStorage.setItem(jwtDefaultConfig.settingCustomerDefaultFormKeyName, JSON.stringify(totalDataForCloneData))
        this.$store.commit(`${this.MODULE_NAME}/SET_SEARCH_DEFAULTS_FORM`, searchDefaults)
        this.$store.commit(`${this.MODULE_NAME}/SET_CONTACT_DEFAULTS_FORM`, contactDefaults)
        this.$store.commit(`${this.MODULE_NAME}/SET_ADDRESS_DEFAULTS_FORM`, addressDefaults)
        this.$store.commit(`${this.MODULE_NAME}/SET_BUSINESS_ENTITY_DEFAULTS_FORM`, businessEntityDefaults)
        this.$store.commit(`${this.MODULE_NAME}/SET_CUSTOMER_VERIFICATION_DEFAULTS_FORM`, customerVerificationDefaults)
        this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_SETTING_CUSTOMER_DEFAULT_FORM_CLONE`, totalDataForCloneData)
      }).catch(err => {
        this.error = true
        this.errorNotification(this, err)
      }).finally(() => {
        this.loading = false
      })
    },
  },
  setup() {
    const MODULE_NAME_CLONE = 'cloneData'
    const {
      searchDefaults, contactDefaults, customerVerificationDefaults, addressDefaults, businessEntityDefaults,
    } = config()
    const MODULE_NAME = 'settings-customer'
    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, customerModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })
    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      searchDefaults,
      contactDefaults,
      addressDefaults,
      businessEntityDefaults,
      customerVerificationDefaults,
    }
  },
}
</script>
